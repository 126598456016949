import React, { useState, useEffect } from 'react';
import { Field, focus, Form, getFormValues, reduxForm } from 'redux-form';
import { __, dateFrom } from '../../core/utils';
import styles from '../../styles/forms/specification_sets/EditSpecificationSetToolbarForm.scss';
import Select from '../../components/Select';
import Tooltip from '../../components/Tooltip';
import classNames from 'classnames';
import DateTimePicker from '../../components/DateTimePicker';
import connect from 'react-redux/es/connect/connect';
import * as _ from 'lodash';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import moment from 'moment';

// ICONS
import MeetingStatusIcon from '../../assets/images/meeting-status-15x15.svg';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import DateIcon from '../../assets/images/date-15x15.svg';
import TaskVisibilityIcon from '../../assets/images/task-visibility-15x15.svg';

const EditSpecificationSetToolbarForm = (props) => {
  const prevProp = React.useRef();

  useEffect(() => {
    if (
      props.anyTouched &&
      prevProp.current &&
      !_.isEqual(prevProp.current, props.values)
    ) {
      props.submit();

      prevProp.current = null;
    } else prevProp.current = props.values; // Update the ref to the current prop for the next render
  }, [props.values]); // Only re-run the effect if 'someProp' changes

  const {
    data,
    user_id,
    values,
    store: { flyout },
  } = props;
  const { status } = values;
  const is_administrator = _.isObject(
    _.find(data.administrators, ['id', user_id])
  );

  const statuses = [
    {
      value: 'draft',
      label: __('specification_sets.status.draft'),
      color: '#9C27B0',
      disabled: false,
    },
    {
      value: 'active',
      label: __('specification_sets.status.active'),
      color: '#5777FC',
      disabled: !_.includes(['draft', 'active'], status),
    },
    {
      value: 'finished',
      label: __('specification_sets.status.finished'),
      color: '#ADD42B',
      disabled: !_.includes(['active'], status),
    },
    {
      value: 'closed',
      label: __('specification_sets.status.closed'),
      color: '#009688',
      disabled: !_.includes(['finished'], status),
    },
    {
      value: 'canceled',
      label: __('specification_sets.status.canceled'),
      color: '#CCCDD1',
      disabled: !_.includes(['draft', 'active', 'finished'], status),
    },
  ];

  const visibilities = [
    {
      value: '0',
      label: __('specification_sets.visibility.private'),
      color: '#CCCCCA',
    },
    {
      value: '1',
      label: __('specification_sets.visibility.public'),
      color: '#029DFC',
    },
  ];

  const disabled = false;
  // !is_administrator || _.includes(['closed', 'canceled'], status);

  return (
    <div className={styles.wrapper}>
      <Form onSubmit={props.handleSubmit} className={styles.formWrapper}>
        <Field
          name='date'
          component={DateTimePicker}
          label={__('Date & Time')}
          footer
          dropdown
          range
          time
          top={32}
          presets
          clearDate
          wrapper={(handleOpen, value) => (
            <Tooltip
              text={__('specification_sets.tooltips.datetime.change-date')}
            >
              <div
                className={classNames(styles.date, disabled && styles.disabled)}
                onClick={handleOpen}
              >
                <DateIcon />{' '}
                {value.start && value.end
                  ? moment
                      .utc(value.start)
                      .local()
                      .startOf('day')
                      .format('X') ==
                    moment.utc(value.end).local().startOf('day').format('X')
                    ? dateFrom(value.start)
                    : dateFrom(value.start) + ' - ' + dateFrom(value.end)
                  : __('specification_sets.flyout.select-date')}
                <ArrowDownSmallIcon />
              </div>
            </Tooltip>
          )}
        />

        <Field
          disabled={disabled}
          name='status'
          component={Select}
          label={__('table.columns.specification_set.status')}
          options={statuses}
          noSort
          dropdown
          top={40}
          icon={<MeetingStatusIcon className={styles.icon} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip text={__('specification_sets.tooltip.icon.status')}>
              <div
                className={classNames(
                  styles.dropdown,
                  disabled && styles.disabled
                )}
                style={
                  activeItem ? { background: activeItem.color } : undefined
                }
                onClick={handleOpen}
              >
                <MeetingStatusIcon className={styles.icon} />
                <div className={styles.button}>
                  {activeItem.label}
                  {!disabled && <ArrowDownSmallIcon />}
                </div>
              </div>
            </Tooltip>
          )}
        />

        <Field
          disabled={true}
          name='visibility'
          component={Select}
          label={__('table.columns.specification_set-visibility')}
          options={visibilities}
          noSort
          dropdown
          top={40}
          icon={<TaskVisibilityIcon className={styles.icon} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip text={__('specification_sets.tooltip.icon.visibility')}>
              <div
                className={classNames(
                  styles.dropdown,
                  disabled && styles.disabled
                )}
                style={
                  activeItem ? { background: activeItem.color } : undefined
                }
                onClick={handleOpen}
              >
                <TaskVisibilityIcon className={styles.icon} />
                <div className={styles.button}>
                  {activeItem.label}
                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Tooltip>
          )}
        />
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.id,
    values: getFormValues('specification_sets.edit_specification_set_toolbar')(
      state
    ),
    dropdown: state.general.dropdown.shown,
    store: state.table.specification_sets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    focus: (field) => {
      dispatch(
        focus('specification_sets.edit_specification_set_toolbar', field)
      );
    },
  };
};

export default reduxForm({
  form: 'specification_sets.edit_specification_set_toolbar',
  enableReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(subscribe(Localization, 'localization')(EditSpecificationSetToolbarForm))
);
