import React, { useEffect, useState } from 'react';
import styles from '../styles/wizards/CreateSpecificationSetWizard.scss';
import { __, getActiveStage, validateForm } from '../core/utils';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import { connect } from 'react-redux';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import AddSpecificationsToSetForm from '../forms/add_specifications_to_set_wizard/AddSpecificationsToSetForm';
import { getStagesLanguages } from '../redux/actions/table/stages';

// ICONS
import AddSpecificationToSetIcon from '../assets/images/add-to-tender-60x60.svg';
import { createSpecificationSet } from '../redux/actions/table/specification_sets';
import CreateSpecificationSetForm from '../forms/create_specification_set_wizard/CreateSpecificationSetForm';

const CreateSpecificationSetWizard = (props) => {
  const [project_languages, set_project_languages] = useState(null);

  useEffect(() => {
    getStagesLanguages(getActiveStage()).then(({ response }) => {
      set_project_languages(response.data);
    });
  }, []);

  const _handleSubmit = (formData) => {
    formData = {
      ...formData,
      followers: _.map(formData.followers, 'id'),
      administrators: _.map(formData.administrators, 'id'),
    };

    return validateForm(
      createSpecificationSet(getActiveStage(), formData).then(() => {
        setNotification(
          __(
            'specification_sets.notification.specification-set-has-been-created'
          )
        );
        props.onClose();
      })
    );
  };

  const { id, avatar_url, email, firstname, lastname } = props.user;

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>
            {__('wizard.specification_sets.title.create-specification-set')}
          </span>
          <span>
            {__('wizard.specification_sets.subtitle.create-specification-set')}
          </span>
        </div>
        <div className={styles.right}>
          <AddSpecificationToSetIcon />
        </div>
      </div>
      {project_languages && (
        <div className={styles.body}>
          <CreateSpecificationSetForm
            projectLanguages={project_languages}
            initialValues={{
              status: 'draft',
              administrators: [
                { id, avatar_url, email, name: `${firstname} ${lastname}` },
              ],
              specification_ids: [],
            }}
            onSubmit={_handleSubmit}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    table: _.get(state.table, 'specification_sets'),
    user: _.get(state, 'auth'),
  };
};

export default withRouter(
  connect(mapStateToProps)(
    subscribe(Localization, 'localization')(CreateSpecificationSetWizard)
  )
);
