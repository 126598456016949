import React, { useState, useEffect } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_specifications_to_set_wizard/AddSpecificationsToSetForm.scss';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { __, getActiveStage, getLocalized } from '../../core/utils';
import Select from '../../components/Select';
import ButtonGroup from '../../components/ButtonGroup';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import classNames from 'classnames';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import Input from '../../components/Input';
import DateTimePicker from '../../components/DateTimePicker';
import Tooltip from '../../components/Tooltip';
import MultiSelect from '../../components/MultiSelect';
import {
  listPossibleSpecificationSetAdministrators,
  listPossibleSpecificationSetFollowers,
} from '../../redux/actions/table/specification_sets';
import PeopleChooser from '../../components/PeopleChooser';

// ICONS
import SpecificationSetStatusIcon from '../../assets/images/tender-status-15x15.svg';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';

const AddSpecificationsToSetForm = (props) => {
  const [possible_followers, set_possible_followers] = useState([]);
  const [possible_administrators, set_possible_administrators] = useState([]);

  useEffect(() => {
    listPossibleSpecificationSetAdministrators(getActiveStage()).then(
      ({ response }) => {
        set_possible_administrators(response.data);
      }
    );

    listPossibleSpecificationSetFollowers(getActiveStage()).then(
      ({ response }) => {
        set_possible_followers(response.data);
      }
    );
  }, []);

  const _handleSubmit = () => {
    setTimeout(() => props.submit(), 10);
  };

  const statuses = [
    {
      value: 'draft',
      label: __('table.columns.task-status-draft'),
      color: '#9C27B0',
    },
  ];

  const languages = _.map(props.projectLanguages, (primary, language_id) => ({
    label: _.get(props.localization.languages, [language_id, 'name']),
    value: language_id,
  }));

  const product_attribute_fields = _.sortBy(
    _.map(props.localization.product_attribute_fields, (field) => ({
      label: getLocalized(field.label, props.application_language_id),
      tooltip: getLocalized(field.description, props.application_language_id),
      value: field.id,
      groupPriority: _.get(props.localization.product_attribute_groups, [
        field.product_attribute_template_group_id,
        'priority',
      ]),
      group: getLocalized(
        _.get(props.localization.product_attribute_groups, [
          field.product_attribute_template_group_id,
          'name',
        ]),
        props.application_language_id
      ),
    })),
    'groupPriority'
  );

  return (
    <Form onSubmit={props.handleSubmit} className={styles.wrapper}>
      <div className={styles.chooser}>
        <div
          className={classNames(
            styles.option,
            !props.values.new_set && styles.selected
          )}
          onClick={() => props.change('new_set', false)}
        >
          <span />
          {__('working-set-specifications.add-specifications-to-existing-set')}
        </div>
        <div
          className={classNames(
            styles.option,
            props.values.new_set && styles.selected
          )}
          onClick={() => props.change('new_set', true)}
        >
          <span />
          {__('working-set-specifications.add-specifications-to-new-set')}
        </div>
      </div>

      {!props.values.new_set ? (
        <div className={styles.form}>
          <Field
            name='set_id'
            component={Select}
            noSort
            searchable
            label={__(
              'working-set-specifications.add-specifications-to-existing-set'
            )}
            options={props.list_possible_specification_sets}
          />
        </div>
      ) : (
        <>
          <div className={styles.top}>
            <Field
              name='status'
              component={Select}
              label={__('table.columns.specification-set-status')}
              options={statuses}
              noSort
              dropdown
              icon={<SpecificationSetStatusIcon className={styles.icon} />}
              wrapper={(handleOpen, activeItem) => {
                return (
                  <Tooltip text={__('specification-set.tooltip.icon.status')}>
                    <div
                      className={styles.dropdown}
                      style={{ background: activeItem.color }}
                      onClick={handleOpen}
                    >
                      <SpecificationSetStatusIcon className={styles.icon} />
                      <div className={styles.button}>
                        {activeItem.label}
                        <ArrowDownSmallIcon />
                      </div>
                    </div>
                  </Tooltip>
                );
              }}
            />
          </div>
          <div className={styles.form}>
            <div className={styles.flex}>
              <div className={styles.f5}>
                <Field
                  name='languages'
                  component={MultiSelect}
                  searchable
                  label={__('Specification Set Languages')}
                  noSort
                  options={languages}
                />
              </div>
            </div>

            <div className={styles.flex}>
              <div className={styles.f5}>
                <Field
                  name='date'
                  component={DateTimePicker}
                  label={__('table.columns.specification-set-date-time')}
                  footer
                  range
                  time
                  presets
                />
              </div>
            </div>
            <Field
              name='title'
              component={Input}
              localized={props.projectLanguages}
              label={__('table.columns.specification-set-title')}
              maxLength={50}
            />

            <div className={styles.flex}>
              <div className={styles.f1}>
                <Field
                  name='administrators'
                  component={PeopleChooser}
                  label={__('table.columns.specification-set-administrators')}
                  searchable
                  options={possible_administrators}
                />
              </div>
              <div className={styles.f1}>
                <Field
                  name='followers'
                  component={PeopleChooser}
                  label={__('table.columns.specification-set-followers')}
                  searchable
                  options={possible_followers}
                />
              </div>
            </div>

            <div>
              <Field
                name='template_field_ids'
                searchable={true}
                component={MultiSelect}
                label={__(
                  'table.columns.specification-set-product-attribute-group-field'
                )}
                options={product_attribute_fields}
                noSort
              />
            </div>
          </div>
        </>
      )}

      <div className={styles.footer}>
        <Button
          lightGray
          medium
          middleText={__('button.cancel')}
          onClick={props.actions.hideAddSpecificationsToSetWizard}
        />
        <ButtonGroup>
          <Button
            lightBlue
            medium
            middleText={__('button.add')}
            spinning={props.submitting}
            onClick={() => _handleSubmit(false)}
          />
          {/* <Button
            lightBlue
            medium
            middleText={__('button.add-go-to-set')}
            spinning={props.submitting}
            onClick={() => _handleSubmit(true)}
          /> */}
        </ButtonGroup>
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    values: getFormValues(
      'add_specifications_to_set_form.add_specifications_to_set'
    )(state),
    stage_id: _.get(state.table, 'working_set_specifications.stage_id'),
    application_language_id: state.auth.language_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideAddSpecificationsToSetWizard: () =>
        dispatch({
          type: 'table.working_set_specifications/SET_TABLE_PARAMS',
          params: {
            add_specifications_to_set_wizard: undefined,
          },
        }),
    },
  };
};

export default withRouter(
  reduxForm({
    form: 'add_specifications_to_set_form.add_specifications_to_set',
  })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(subscribe(Localization, 'localization')(AddSpecificationsToSetForm))
  )
);
