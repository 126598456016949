import React, { useEffect, useState } from 'react';
import styles from '../styles/wizards/AddWorkingSetSpecificationsToSetWizard.scss';
import {
  __,
  getActiveStage,
  redirect,
  setFormErrors,
  validateForm,
} from '../core/utils';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import { connect } from 'react-redux';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import AddSpecificationsToSetForm from '../forms/add_specifications_to_set_wizard/AddSpecificationsToSetForm';
import { getStagesLanguages } from '../redux/actions/table/stages';

// ICONS
import AddSpecificationToSetIcon from '../assets/images/add-to-tender-60x60.svg';
import {
  addSpecificationsToExistingSet,
  createSpecificationSet,
  listPossibleSpecificationSets,
} from '../redux/actions/table/specification_sets';

const AddSpecificationsToSetWizard = (props) => {
  const [project_languages, set_project_languages] = useState(null);
  const [
    list_possible_specification_sets,
    set_list_possible_specification_sets,
  ] = useState([]);

  useEffect(() => {
    getStagesLanguages(getActiveStage()).then(({ response }) => {
      set_project_languages(response.data);
    });

    listPossibleSpecificationSets(props.match.params.stage).then(
      ({ response }) => {
        set_list_possible_specification_sets(
          _.map(response.data, (item) => {
            const { id, title, identifier } = item;

            return {
              label: 'S-' + _.padStart(identifier, 4, '0') + ': ' + title,
              value: id,
            };
          })
        );
      }
    );
  }, []);

  const _handleSubmit = (formData) => {
    // const { specification_set_id } = formData;

    // if (!specification_set_id) {
    //   return setFormErrors(
    //     'working-set-specifications.add_working_set_specifications_to_set',
    //     {
    //       specification_set_id: ['Field is empty'],
    //     }
    //   );
    // }

    const { selected_rows } = props;

    formData = {
      ...formData,
      followers: _.map(formData.followers, 'id'),
      administrators: _.map(formData.administrators, 'id'),
    };

    let action;

    if (formData.new_set) {
      action = createSpecificationSet(getActiveStage(), {
        ...formData,
        specification_ids: selected_rows,
      });
    } else {
      action = addSpecificationsToExistingSet(formData.set_id, {
        specification_ids: selected_rows,
      });
    }

    return validateForm(
      action.then(() => {
        setNotification(
          __(
            'working-set-specifications.notification.selected-rows-have-been-added-to-the-set'
          )
        );
        props.onClose();
      })
    );
  };

  const { id, avatar_url, email, firstname, lastname } = props.user;

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>
            {__(
              'wizard.working-set-specifications.title.add-specification-to-set'
            )}
          </span>
          <span>
            {__(
              'wizard.working-set-specifications.subtitle.add-specification-to-set'
            )}
          </span>
        </div>
        <div className={styles.right}>
          <AddSpecificationToSetIcon />
        </div>
      </div>
      <div className={styles.body}>
        {project_languages && (
          <AddSpecificationsToSetForm
            projectLanguages={project_languages}
            initialValues={{
              new_set: false,
              status: 'draft',
              administrators: [
                { id, avatar_url, email, name: `${firstname} ${lastname}` },
              ],
            }}
            list_possible_specification_sets={list_possible_specification_sets}
            onSubmit={_handleSubmit}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected_rows: _.get(
      state.table,
      'working_set_specifications.selected_rows'
    ),
    table: _.get(state.table, 'working_set_specifications'),
    user: _.get(state, 'auth'),
  };
};

export default withRouter(
  connect(mapStateToProps)(
    subscribe(Localization, 'localization')(AddSpecificationsToSetWizard)
  )
);
