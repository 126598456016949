import * as React from 'react';
import { Component } from 'react';
import * as _ from 'lodash';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import {
  __,
  dateFrom,
  getActiveStage,
  redirect,
  setTableParams,
} from '../../core/utils';
import Table from '../../components/Table';
import {
  listSpecificationSets,
  readSpecificationSet,
} from '../../redux/actions/table/specification_sets';
import Tooltip from '../../components/Tooltip';
import classNames from 'classnames';
import styles from '../../styles/views/specifications/SpecificationSets.scss';
import Avatar from '../../components/Avatar';
import TableNA from '../../components/TableNA';
import SpecificationSetsFlyout from '../../flyouts/specification_sets/SpecificationSetsFlyout';
import { setPlusActions } from '../../redux/actions/general/plus';
import connect from 'react-redux/es/connect/connect';

// ICONS
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';

const mapStateToProps = (state) => {
  return {
    table: state.table['specification_sets'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.specification_sets/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class SpecificationSets extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    enableProjectsDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/specification_deliveries' +
          (this.props.match.params.type == 'my' ? '/my' : '')
      )
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/specification_deliveries' +
          (this.props.match.params.type == 'my' ? '/my' : '')
      )
    );

    createSidebar({
      title: __('submenu.title.specifications'),
      items: [
        {
          title: __('submenu.title.current-set-specifications'),
          link: () => '/stages/' + getActiveStage() + '/specifications/current',
        },
        {
          title: __('submenu.title.working-set-specifications'),
          link: () => '/stages/' + getActiveStage() + '/specifications/working',
        },
        {
          title: __('submenu.title.specification-sets'),
          link: () => '/stages/' + getActiveStage() + '/specifications/sets',
          active: true,
        },
        {
          title: __('submenu.title.deliveries'),
          link: () =>
            '/stages/' + getActiveStage() + '/specification_deliveries',
        },
        {
          title: __('submenu.title.approvals'),
          link: () =>
            '/stages/' + getActiveStage() + '/specification_approvals',
        },
      ],
    });

    setPlusActions([
      {
        title: __('specification_sets.table.button.create'),
        onClick: () =>
          this.props.actions.setTableParams({
            wizard_active: true,
            x: undefined,
            y: undefined,
            stage_id: undefined,
          }),
      },
    ]);
  }

  componentDidUpdate(prevProps) {
    //if there are new filter options available, update sidebar
    // if (!_.isEqual(this.props.table?.filters, prevProps.table?.filters)) {
    //   const items = _.keys(_.get(this.props.table.filters, 'type.items'));

    //   const all_ids = items;

    //   updateSidebar('items.0.items', [
    //     {
    //       title: __('submenu.title.tenders'),
    //       onClick: () => setURLParam('filter.type', ''),
    //       active: () => {
    //         const active_types = _.split(getURLParam('filter.type'), '|');

    //         const intersected = _.intersection(active_types, items);

    //         return (
    //           _.isEmpty(getURLParam('filter.type')) ||
    //           _.size(intersected) == _.size(items)
    //         );
    //       },
    //       checkbox: {
    //         status: () => {
    //           const active_types = _.split(getURLParam('filter.type'), '|');

    //           const intersected = _.intersection(active_types, items);

    //           if (_.isEmpty(getURLParam('filter.type'))) {
    //             return 'checked';
    //           }

    //           if (_.isEmpty(intersected)) {
    //             return 'unchecked';
    //           } else if (_.size(intersected) == _.size(items)) {
    //             return 'checked';
    //           } else {
    //             return 'neutral';
    //           }
    //         },
    //         onClick: () => {
    //           const active_types = _.split(getURLParam('filter.type'), '|');

    //           const intersected = _.intersection(active_types, items);

    //           if (
    //             _.size(intersected) == _.size(items) ||
    //             _.isEmpty(getURLParam('filter.type'))
    //           ) {
    //             setURLParam('filter.type', 'n/a');
    //           } else {
    //             setURLParam('filter.type', '');
    //           }
    //         },
    //       },
    //     },
    //     ..._.map(items, (type) => ({
    //       title: __('tenders.tooltip.icon.' + type),
    //       onClick: () => setURLParam('filter.type', type),
    //       active: () => {
    //         const active_types = _.split(getURLParam('filter.type'), '|');

    //         return (
    //           _.isEmpty(getURLParam('filter.type')) ||
    //           _.includes(active_types, type)
    //         );
    //       },
    //       checkbox: {
    //         status: () => {
    //           const active_types = _.split(getURLParam('filter.type'), '|');

    //           if (_.isEmpty(getURLParam('filter.type'))) {
    //             return 'checked';
    //           }

    //           return _.includes(active_types, type) ? 'checked' : 'unchecked';
    //         },
    //         onClick: () => {
    //           const setTypes = (types) => {
    //             types = _.trim(
    //               _.join(
    //                 _.reject(types, (type) => type == 'n/a'),
    //                 '|'
    //               ),
    //               '|'
    //             );

    //             setURLParam('filter.type', _.isEmpty(types) ? 'n/a' : types);
    //           };

    //           const active_types =
    //             getURLParam('filter.type') == ''
    //               ? all_ids
    //               : _.split(getURLParam('filter.type'), '|');

    //           if (_.includes(active_types, type)) {
    //             setTypes(_.reject(active_types, (row) => row == type));
    //           } else {
    //             active_types.push(type);

    //             setTypes(active_types);
    //           }
    //         },
    //       },
    //     })),
    //   ]);
    // }

    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      readSpecificationSet(this.props.table.clicked_row).then(
        ({ response }) => {
          setTableParams('specification_sets', {
            flyout: response.data,
          });
        }
      );
    }
  }

  render() {
    const { specification_groups, specification_subgroups } =
      this.props.localization;
    const { project_language } = this.state;

    const columns = [
      {
        key: 'identifier',
        name: __('table.columns.specification-set.code'),
        value: (value) => 'IS-' + value,
        width: 80,
        default: true,
        desc: true,
        required: true,
      },
      {
        key: 'title',
        name: __('table.columns.specification-set.title'),
        required: true,
        mobileVisible: true,
      },
      {
        key: 'administrators',
        name: __('table.columns.tender.administrators'),
        value: (administrator) => (
          <div className={styles.administrators}>
            {_.size(administrator) == 1 &&
              _.map(_.take(administrator, 1), (user, i) => (
                <Avatar
                  key={i}
                  avatar_url={user.avatar_url}
                  name={user.fullname}
                  active={user.active}
                />
              ))}
            {_.size(administrator) > 1 && (
              <Tooltip text={_.map(administrator, 'fullname').join('\n')}>
                <div className={styles.more}>
                  <span>{_.size(administrator)}</span>
                </div>
              </Tooltip>
            )}
          </div>
        ),
        sortable: false,
        width: 60,
        active: false,
      },
      {
        key: 'followers',
        name: __('table.columns.tender.followers'),
        value: (follower) => (
          <div className={styles.followers}>
            {_.size(follower) == 1 &&
              _.map(_.take(follower, 1), (user, i) => (
                <Avatar
                  key={i}
                  avatar_url={user.avatar_url}
                  name={user.fullname}
                  active={user.active}
                />
              ))}
            {_.size(follower) > 1 && (
              <Tooltip text={_.map(follower, 'fullname').join('\n')}>
                <div className={styles.more}>
                  <span>{_.size(follower)}</span>
                </div>
              </Tooltip>
            )}
          </div>
        ),
        sortable: false,
        width: 60,
        active: false,
      },
      {
        key: 'status',
        name: __('table.columns.specification-set.status'),
        value: (status) => {
          switch (status) {
            case 'draft':
              return (
                <Tooltip
                  text={__('table.columns.specification-set-status-draft')}
                >
                  <span className={classNames(styles.balloon, styles.draft)}>
                    {__('table.columns.specification-set-status-draft-short')}
                  </span>
                </Tooltip>
              );
            case 'started':
              return (
                <Tooltip
                  text={__('table.columns.specification-set-status-started')}
                >
                  <span className={classNames(styles.balloon, styles.started)}>
                    {__('table.columns.specification-set-status-started-short')}
                  </span>
                </Tooltip>
              );
            case 'finished':
              return (
                <Tooltip
                  text={__('table.columns.specification-set-status-finished')}
                >
                  <span className={classNames(styles.balloon, styles.finished)}>
                    {__(
                      'table.columns.specification-set-status-finished-short'
                    )}
                  </span>
                </Tooltip>
              );
            case 'closed':
              return (
                <Tooltip
                  text={__('table.columns.specification-set-status-closed')}
                >
                  <span className={classNames(styles.balloon, styles.closed)}>
                    {__('table.columns.specification-set-status-closed-short')}
                  </span>
                </Tooltip>
              );
            case 'canceled':
              return (
                <Tooltip
                  text={__('table.columns.specification-set-status-canceled')}
                >
                  <span className={classNames(styles.balloon, styles.canceled)}>
                    {__(
                      'table.columns.specification-set-status-canceled-short'
                    )}
                  </span>
                </Tooltip>
              );
          }
        },
        width: 50,
        default: true,
        fullWidth: true,
      },
      {
        key: 'visibility',
        name: __('table.columns.specification-set.visibility'),
        value: (visibility) =>
          visibility == '1' ? (
            <Tooltip
              text={__('table.columns.specification-set-visibility-public')}
            >
              <span className={classNames(styles.balloon, styles.public)}>
                {__('table.columns.specification-set-visibility-public-short')}
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              text={__('table.columns.specification-set-visibility-private')}
            >
              <span className={classNames(styles.balloon, styles.private)}>
                {__('table.columns.specification-set-visibility-private-short')}
              </span>
            </Tooltip>
          ),
        width: 50,
      },
      {
        key: 'created_at',
        name: __('table.columns.specification-set.created_at'),
        value: (value) => (value ? dateFrom(value) : <TableNA />),
        width: 80,
        active: false,
      },
      {
        key: 'created_by',
        name: __('table.columns.specification-set.created-by'),
        value: (user) => (
          <Avatar
            avatar_url={user.avatar_url}
            name={user.fullname}
            active={user.active}
          />
        ),
        width: 50,
      },
    ];

    const singleActions = (spec_set_id) => {
      const actions = [];

      actions.push(
        <Tooltip
          text={__(
            'specification-sets.tooltip.icon.specification-set-properties'
          )}
          key={_.uniqueId()}
        >
          <InfoPositiveIcon
            className=''
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('specification_sets', {
                clicked_row: spec_set_id,
                actioned_row: undefined,
              });
            }}
          />
        </Tooltip>
      );

      return actions;
    };

    const groupActions = () => [];

    const preview = (
      <SpecificationSetsFlyout
        data={this.props.table.flyout}
        readAction={(specification_set_id) => {
          return new Promise((resolve) => {
            readSpe(getURLParam('id') || specification_set_id).then(
              ({ response }) => {
                setTableParams('specification_sets', {
                  flyout: response.data,
                });

                resolve(response);
              }
            );
          });
        }}
      />
    );

    return (
      <Table
        listAction={() => listSpecificationSets(this.props.match.params.stage)}
        preview={this.props.table.flyout ? preview : []}
        name='specification_sets'
        title={__('submenu.title.specification-sets')}
        columns={columns}
        onLoad={() => listActiveModules()}
        singleActions={singleActions}
        onRowClick={(id) => {
          redirect(
            '/stages/' +
              this.props.match.params.stage +
              '/specifications/sets/' +
              id
          );
        }}
      />
    );
  }
}

export default SpecificationSets;
