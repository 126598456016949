import * as React from 'react';
import { Component, createRef } from 'react';
import { hideSidebar } from '../../redux/actions/general/sidebar';
import {
  __,
  dateTimeFrom,
  mapStateToProps,
  redirect,
  setTableParams,
  transformToListPairs,
} from '../../core/utils';
import * as _ from 'lodash';
import { getStagesLanguages } from '../../redux/actions/table/stages';
import { withRouter } from 'react-router';
import styles from '../../styles/views/specifications/SpecificationSetItems.scss';
import Tooltip from '../../components/Tooltip';
import autobind from 'autobind-decorator';
import { getPreferences, updatePreferences } from '../../redux/actions/profile';
import {
  createGenericSpecification,
  readSpecificationSet,
  readSpecificationSetDynamicColumns,
  readSpecificationSetItems,
} from '../../redux/actions/table/specification_sets';
import {
  listPossibleAddressBookManufacturers,
  listPossibleAddressBookSuppliers,
} from '../../redux/actions/table/address_book_companies';
import {
  listPossibleSpecificationResponsibleUsers,
  readWorkingSetSpecification,
} from '../../redux/actions/table/working_set_specifications';
import TableSpecificationSetItems from './helpers/TableSpecificationSetItems';
import { hideDropdown } from '../../redux/actions/general/dropdown';
import classNames from 'classnames';
import Dropdown from '../../components/Dropdown';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import SpecificationSetsFlyout from '../../flyouts/specification_sets/SpecificationSetsFlyout';

// ICONS
import ArrowLeftMiddleIcon from '../../assets/images/arrow-left-middle-15x15.svg';
import UncheckedIcon from '../../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../../assets/images/checked-15x15.svg';
import ColumnSelectorIcon from '../../assets/images/column-selector-16x16.svg';
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';
import SpecificationsIcon from '../../assets/images/specifications-24x24.svg';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import WorkingSetSpecificationsFlyout from '../../flyouts/working_set_specifications/WorkingSetSpecificationsFlyout';

@mapStateToProps((state) => ({
  store: state.table['specification_sets'],
  auth: state.auth,
  specification_id: _.get(state.table, [
    'working_set_specifications',
    'clicked_row',
  ]),
  dropdown: state.general.dropdown.shown,
}))
@withRouter
class SpecificationSetItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused_id: null,
      space_allocation: {},
      expanded_rows: [],
      isDataLoaded: false,
      columns: [],
    };

    this.tableRef = createRef();
    this.nameRefs = [];

    this.savePreferencesTimeout = null;
  }

  @autobind
  _refreshSpecificationSetItems() {
    readSpecificationSetItems(this.props.match.params.specification_set);

    hideDropdown();
  }

  componentDidMount() {
    listActiveModules();

    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/specification_sets')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/specification_sets')
    );

    getStagesLanguages(this.props.match.params.stage).then(({ response }) => {
      setTableParams('specification_sets', {
        project_languages: response.data,
        language_id: _.findKey(response.data, (primary) => primary == true),
      });
    });

    readSpecificationSet(this.props.match.params.specification_set);

    readSpecificationSetItems(this.props.match.params.specification_set)
      .then(({ response }) => {
        readSpecificationSetDynamicColumns(
          this.props.match.params.specification_set
        );

        this.setState({ preview: response.data });
      })
      // .then(() => {
      //   this._refreshSpecificationSetItems(true);
      // })
      .then(() => {
        getPreferences(
          `table.specification_set_items.${this.props.match.params.specification_set}`
        ).then(({ response: { data } }) => {
          const expanded_rows = _.get(data, 'expanded_rows', []);

          this.tableRef.setExpandedRowKeys(expanded_rows);
          this.setState({ columns: this.tableRef.props?.columns });

          const user_preference_params = _.transform(
            data,
            (params, value, key) => {
              if (_.startsWith(key, 'filter_')) {
                params[key.replace('filter_', 'filter.')] = _.toString(value);
              } else {
                params[key] = value;
              }
            },
            {}
          );

          setTableParams(
            'specification_sets',
            _.pick(user_preference_params, [
              'disabled_columns',
              'pinned_columns',
              'view',
            ])
          );

          this.setState({
            expanded_rows,
            tempDisabledColumns: user_preference_params.disabled_columns,
          });
        });
      });

    listPossibleAddressBookManufacturers().then(({ response }) => {
      this.setState({
        possible_address_book_manufacturers: transformToListPairs(
          response.data
        ),
      });
    });

    listPossibleAddressBookSuppliers().then(({ response }) => {
      this.setState({
        possible_address_book_suppliers: transformToListPairs(response.data),
      });
    });

    listPossibleSpecificationResponsibleUsers(
      this.props.match.params.stage
    ).then(({ response }) => {
      this.setState({
        possible_specification_responsible_users: transformToListPairs(
          response.data
        ),
      });
    });

    hideSidebar();
  }

  @autobind
  _saveTablePreferences() {
    if (this.savePreferencesTimeout) clearTimeout(this.savePreferencesTimeout);

    this.savePreferencesTimeout = setTimeout(() => {
      updatePreferences(
        `table.specification_set_items.${this.props.match.params.specification_set}`,
        {
          expanded_rows: this.state.expanded_rows,
        }
      );
    }, 1000);
  }

  @autobind
  _toggleColumn(column) {
    this.setState({
      tempDisabledColumns: _.xor(this.state.tempDisabledColumns, [column]),
    });
  }

  @autobind
  _resetColumnsToDefault() {
    this.setState({
      tempDisabledColumns: _.map(
        _.filter(this.state.columns, ({ active }) => !active),
        'key'
      ),
    });
  }

  @autobind
  _createGenericSpecification(specification_subgroup_id) {
    createGenericSpecification(this.props.match.params.specification_set, {
      specification_subgroup_id,
    }).then(() => {
      this._refreshSpecificationSetItems();
    });
  }

  @autobind
  _addSpecification() {
    setTableParams('working_set_specifications', {
      wizard_active: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.store.clicked_row &&
      this.props.store.clicked_row !== prevProps.store.clicked_row
    ) {
      if (!prevProps.store.clicked_row) {
        this.setState({ preview: undefined });
      }

      readSpecificationSet(this.props.store.clicked_row).then(
        ({ response }) => {
          setTableParams('specification_sets', {
            flyout: response.data,
          });
        }
      );
    }
  }

  render() {
    const {
      space_allocation,
      expanded_rows,
      focused_id,
      possible_address_book_manufacturers,
      possible_address_book_suppliers,
      possible_specification_responsible_users,
    } = this.state;

    const {
      language_id,
      project_languages,
      project_currency,
      flyout,
      items,
      dynamic_columns,
      disabled_columns,
    } = this.props.store;
    const { localization, dropdown, specification_id } = this.props;

    const footer_buttons = [
      {
        label: __('specification-set-item.label.add-generic-specification'),
        tooltip: __('specification-set-item.tooltip.add-generic-specification'),
        disabled: false,
        onClick: () => this._createGenericSpecification(),
      },
      {
        label: __('specification-set.label.add-specification'),
        tooltip: __('specification-set.tooltip.add-specification'),
        disabled: false,
        onClick: () => this._addSpecification(),
      },
    ];

    const specification_action_row = [
      {
        label: __('specification-set-item.button.add-generic-specification'),
        onClick: (specification_subgroup_id) =>
          this._createGenericSpecification(specification_subgroup_id),
      },
    ];

    const project_language_id = _.findKey(
      project_languages,
      (primary) => primary == true
    );

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div className={styles.set}>
              <span>
                <strong>
                  {'IS-' + _.padStart(flyout?.identifier, 4, '0')}:
                </strong>
                {_.get(
                  flyout?.title,
                  _.findKey(
                    _.get(flyout, 'project_languages'),
                    (primary) => primary == 1
                  )
                )}
              </span>
            </div>
            <div className={styles.details}>{`${dateTimeFrom(
              flyout?.start_date
            )} - ${dateTimeFrom(flyout?.end_date)}`}</div>
          </div>
          <div className={styles.toolbar}>
            <div
              className={styles.left}
              onClick={() => {
                redirect(
                  '/stages/' +
                    this.props.match.params.stage +
                    '/specifications/sets'
                );
              }}
            >
              <ArrowLeftMiddleIcon />{' '}
              {__('specification-sets.tools.back-to-specification-set-list')}
            </div>
            <div className={styles.right}>
              <Tooltip
                text={__(
                  'specification-sets.tooltip.icon.specification-set-properties'
                )}
              >
                <InfoPositiveIcon
                  className={styles.info}
                  onClick={() =>
                    setTableParams('specification_sets', {
                      clicked_row: flyout.id,
                      stage_id: this.props.match.params.stage,
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        </div>

        <div className={styles.heading}>
          <div className={styles.title}>
            <span>
              {__('specification-set.items.specification-set' + flyout?.status)}
            </span>
          </div>
          <div className={styles.details}>
            <div className={styles.target}>
              <SpecificationsIcon className={styles.icon} />
              <Tooltip
                text={__(
                  'specification-sets.tooltip.icon.specification-set-properties'
                )}
              >
                <span
                  onClick={() =>
                    setTableParams('specification_sets', {
                      clicked_row: flyout.id,
                      stage_id: this.props.match.params.stage,
                    })
                  }
                >
                  {'IS-' + _.padStart(flyout?.identifier, 4, '0')}
                  <ArrowDoubleRightSmallIcon />
                </span>
              </Tooltip>
            </div>

            <div className={styles.columnSelector}>
              <Dropdown
                closable={false}
                top={29}
                right={-19}
                name='column_selector'
                header={
                  <div className={styles.columnSelectorHeader}>
                    {__('table.columns.select-columns')}
                  </div>
                }
                content={
                  <div className={styles.columnSelectorContent}>
                    {_.map(
                      _.filter(
                        this.state.columns,
                        ({ required, key }) => !required && key != 'placeholder'
                      ),
                      ({ title, key }) => (
                        <div
                          onClick={() => this._toggleColumn(key)}
                          key={key}
                          className={styles.column}
                        >
                          {title}

                          {_.includes(this.state.tempDisabledColumns, key) && (
                            <UncheckedIcon className={styles.unchecked} />
                          )}
                          {!_.includes(this.state.tempDisabledColumns, key) && (
                            <CheckedIcon className={styles.checked} />
                          )}
                        </div>
                      )
                    )}
                    <div
                      onClick={this._resetColumnsToDefault}
                      className={styles.column}
                    >
                      {__('table.columns.reset-to-default')}
                    </div>
                    <div className={styles.saveFooter}>
                      <ButtonGroup right>
                        <Button
                          lightGray
                          medium
                          middleText={__('button.cancel')}
                          onClick={() => {
                            hideDropdown();
                          }}
                        />
                        <Button
                          lightBlue
                          medium
                          middleText={__('button.done')}
                          onClick={() => {
                            setTableParams('specification_sets', {
                              disabled_columns:
                                _.size(this.state.tempDisabledColumns) > 0
                                  ? this.state.tempDisabledColumns
                                  : null,
                            });

                            readSpecificationSetItems(
                              this.props.match.params.specification_set
                            );

                            hideDropdown();
                          }}
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                }
              >
                <Tooltip text={__('content.tooltip.table.columns')}>
                  <ColumnSelectorIcon />
                </Tooltip>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className={styles.table}>
          <TableSpecificationSetItems
            name='specification_set_items'
            title={__('specification_set_items')}
            localization={localization}
            languageId={language_id}
            projectCurrency={project_currency}
            focusedId={focused_id}
            data={items}
            dynamicColumns={dynamic_columns}
            disabledColumns={disabled_columns}
            project_languages={project_languages}
            spaceAllocationData={space_allocation}
            setFocusedId={(focused_id) => this.setState({ focused_id })}
            setRef={(ref) => (this.tableRef = ref)}
            tableRef={this.tableRef}
            expandedRows={expanded_rows}
            setExpandedRows={(expanded_rows) => {
              this.setState({ expanded_rows });

              this._saveTablePreferences();
            }}
            setNameRef={(id, ref) => (this.nameRefs[id] = ref)}
            possible_address_book_manufacturers={
              possible_address_book_manufacturers
            }
            possible_address_book_suppliers={possible_address_book_suppliers}
            possible_specification_responsible_users={
              possible_specification_responsible_users
            }
            refreshData={this._refreshSpecificationSetItems}
            specificationActionRow={specification_action_row}
            dropdown={dropdown}
            // store={this.props.store}
            // filters={filters}
            // groupActions={this.groupActions}
            // refreshData={this._refreshWorkingSetBOQ}
            // filterData={this._filterWorkingSetBOQ}
            // languageSwitcher={language_switcher}
            // setLanguageSwitcher={(value) =>
            //   this.setState({ language_switcher: value })
            // }
            // dropdown={dropdown}
            // addRowButtons={add_row_buttons}
            // sidebar={this.props.sidebar}
            // possible_boq_groups={this.state.possible_boq_groups}
            // possible_boq_subgroups={this.state.possible_boq_subgroups}
            // handleCreateTemplateByLevel={this._handleCreateTemplateByLevel}
            // nameRefs={this.nameRefs}
          />
        </div>

        <div className={styles.footer}>
          {/* Button for adding various types of specifications */}
          <Dropdown
            top={10}
            name='add-specification-type'
            wrapperClassName={styles.dropdownWrapper}
            header={
              <div className={styles.dropdownHeader}>
                {__(
                  'specification-sets.footer.button.header.add-specification-type'
                )}
              </div>
            }
            content={
              <div className={styles.dropdownContent}>
                {_.map(footer_buttons, (button, i) => (
                  <div
                    className={classNames(
                      styles.item,
                      classNames(button.disabled && styles.disabled)
                    )}
                    key={i}
                    onClick={() => {
                      !button.disabled && button.onClick();
                      hideDropdown();
                    }}
                  >
                    <Tooltip text={button.tooltip}>
                      <span>{button.label}</span>
                    </Tooltip>
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.plus}>
              <strong>+</strong>
              <span>
                {__(
                  'specification-set.footer.button.label.add-specification-type'
                )}
              </span>
            </div>
          </Dropdown>
        </div>

        <div
          className={classNames(
            styles.flyout,
            this.props.store.clicked_row && styles.active
          )}
        >
          {this.props.store.clicked_row && (
            <SpecificationSetsFlyout
              data={this.props.store.flyout}
              readAction={(specification_set_id) => {
                return new Promise((resolve) => {
                  readSpecificationSet(
                    this.props.match.params.specification_set
                  ).then(({ response }) => {
                    setTableParams('specification_sets', {
                      flyout: response.data,
                    });

                    readSpecificationSetItems(
                      this.props.match.params.specification_set
                    ).then(({ response }) => {
                      resolve(response);
                    });
                  });
                });
              }}
            />
          )}
        </div>

        {specification_id && (
          <div className={styles.flyout}>
            <WorkingSetSpecificationsFlyout
              readAction={(specification_id) => {
                return new Promise((resolve) => {
                  readWorkingSetSpecification(
                    this.props.match.params.tender,
                    specification_id
                  ).then(({ response }) => {
                    setTableParams('working_set_specifications', {
                      flyout: response.data,
                      clicked_row: response.data.id,
                    });
                    resolve(response);
                  });
                });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SpecificationSetItems;
