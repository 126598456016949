import * as _ from 'lodash';
import styles from '../../../styles/views/specifications/SpecificationSetItems.scss';
import {
  __,
  getLocalized,
  setTableParams,
  toBoolean,
  unformatMoney,
  validateForm,
} from '../../../core/utils';
import classNames from 'classnames';
import BaseTable from 'react-base-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Tooltip from '../../../components/Tooltip';
import { withRouter } from 'react-router';
import TableNA from '../../../components/TableNA';
import Image from '../../../components/Image';
import SearchableDropdown from '../../../components/SearchableDropdown';
import {
  readWorkingSetSpecification,
  updateSpecificationBasicData,
  updateSpecificationPrice,
  updateSpecificationProductAttributesGroupFields,
} from '../../../redux/actions/table/working_set_specifications';
import NumericInput from './NumericInput';
import ColorPicker from '../../../components/ColorPicker';
import MaterialPicker from '../../../components/MaterialPicker';
import MultiSelect from '../../../components/MultiSelect';
import {
  updateBOQCalculationQuantity,
  updateBOQCalculationUnit,
  updateBOQPositionQuantity,
} from '../../../redux/actions/table/working_set_boq';

import {
  updateGenericSpecificationProductAttributesGroupFields,
  updateSpecificationSetFixedColumn,
} from '../../../redux/actions/table/specification_sets';
import Dropdown from '../../../components/Dropdown';
import { ContextMenu, MenuItem, showMenu } from 'react-contextmenu';

// ICONS
import UncheckedIcon from '../../../assets/images/unchecked-15x15.svg';
import NeutralIcon from '../../../assets/images/neutral-15x15.svg';
import CheckedIcon from '../../../assets/images/checked-15x15.svg';
import ExpandIcon from './ExpandIcon';
import ArrowDownSmallIcon from '../../../assets/images/arrow-down-small-15x15.svg';
import ConvertSpecificationIcon from '../../../assets/images/convert-to-specification-16x16.svg';
import MenuVIcon from '../../../assets/images/menu-v-15x15.svg';
import MoveIcon from '../../../assets/images/move-16x16.svg';
import ArrowDoubleRightSmallIcon from '../../../assets/images/arrow-double-right-small-15x15.svg';
import EditIcon from '../../../assets/images/edit-16x16.svg';

const TableSpecificationSetItems = ({
  focusedId,
  setFocusedId,
  data,
  refreshData,
  setRef,
  setNameRef,
  localization,
  tableRef,
  setExpandedRows,
  expandedRows,
  name,
  languageId,
  title,
  possible_address_book_manufacturers,
  possible_address_book_suppliers,
  possible_specification_responsible_users,
  disabledColumns,
  dynamicColumns,
  specificationActionRow,
  dropdown,
  ...props
}) => {
  const {
    specification_groups,
    specification_subgroups,
    units,
    product_attribute_fields,
    unit_groups,
    boq_supply_type_groups,
    boq_supply_types,
  } = localization;

  const [selectedItemContext, setSelectedItemContext] = useState({});

  const [activeComponent, setActiveComponent] = useState('');

  const [checked, setChecked] = useState([]);
  const [isUnallocatedCollapsed, setUnallocatedCollapsed] = useState(true);

  const search = useRef();

  const getDeepIds = useCallback((rows) => {
    const ids = [];

    const process = (rows) =>
      _.each(rows, (row) => {
        row.id != 'UN' && _.isEmpty(row.children) && ids.push(row.id);

        !_.isEmpty(row.children) && process(row.children);
      });

    process(rows);

    return ids;
  }, []);

  const getSelectedRows = useCallback((rows) => {
    let result = _.cloneDeep(rows);

    function filterCheckedNotFalse(item) {
      if (item.children && Array.isArray(item.children)) {
        item.children = _.map(
          _.filter(
            item.children,
            (child) => !('checked' in child) || child.checked !== false
          ),
          (row) => filterCheckedNotFalse(row)
        );
      }

      return data;
    }

    return _.map(
      _.filter(result, (item) => item.checked != false),
      (item) => filterCheckedNotFalse(item)
    );
  }, []);

  const final_data = useMemo(() => {
    const rows = isUnallocatedCollapsed
      ? _.reject(data, (row) => _.includes(row.id, 'UN-'))
      : data;

    const process = (rows) =>
      _.map(rows, (row) => {
        let is_checked = false;

        if (_.isEmpty(row.children)) {
          if (_.includes(checked, row.id)) {
            is_checked = true;
          }
        } else {
          const child_ids = getDeepIds(row.children);
          const checked_child_ids = _.intersection(checked, child_ids);

          if (checked_child_ids.length != 0) {
            if (child_ids.length == checked_child_ids.length) {
              is_checked = true;
            } else {
              is_checked = 'neutral';
            }
          }
        }

        return {
          ...row,
          checked: is_checked,
          children: _.isEmpty(row.children) ? undefined : process(row.children),
        };
      });

    return process(rows);
  }, [isUnallocatedCollapsed, data, checked]);

  const data_ids = useMemo(() => getDeepIds(final_data), [final_data]);

  const selected_rows = useMemo(
    () => getSelectedRows(final_data),
    [final_data]
  );

  const _handleMasterExpand = (expanded_rows, unallocated_collapsed) => {
    setExpandedRows(expanded_rows); //set expanded rows in local state
    setUnallocatedCollapsed(unallocated_collapsed); //collapse 'Unallocated' group
    tableRef.setExpandedRowKeys(expanded_rows); //set expanded rows in dependency state
  };

  const _handleCheckbox = useCallback(
    (id) => {
      const target_ids = _.filter(data_ids, (child_id) =>
        _.startsWith(child_id, id)
      );

      const intersected = _.intersection(target_ids, checked).length;

      if (intersected == 0 || intersected != target_ids.length) {
        setChecked(_.union(checked, target_ids)); //select all
      } else {
        setChecked(_.difference(checked, target_ids)); //select none
      }
    },
    [data_ids, checked]
  );

  const _handleMasterCheckbox = useCallback(() => {
    if (data_ids.length == checked.length && checked.length !== 0) {
      setChecked([]);
    } else {
      setChecked(data_ids);
    }
  }, [data_ids, checked]);

  const _handleFixedDataSubmit = (rowData, changes = {}) => {
    let formData = {
      id: rowData.id,
      team_id: rowData.team_id,
      description: rowData.description,
      manufacturer_company_id: rowData.manufacturer_company_id,
      supplier_company_id: rowData.supplier_company_id,
      responsible_user_id: rowData.responsible_user_id,
      unit_id: changes.unit_id || rowData.unit_id,
      quantity: changes.quantity || rowData.quantity,
      price: changes.price || rowData.price,
    };

    let action = null; // Initialize action

    if (rowData.type === 'specification' || rowData.level == 2) {
      if (changes.unit_id) {
        action = updateBOQCalculationUnit(
          rowData.boq_calculation_id,
          changes.unit_id
        );
      } else if (changes.price) {
        action = updateSpecificationPrice(_.last(_.split(rowData.id, '-')), {
          price: changes.price,
        });
      } else if (changes.quantity) {
        const [specification_subgroup_id, specification_id, position_id] =
          _.split(rowData.id, '-', 3);
        action = position_id
          ? updateBOQPositionQuantity(position_id, changes.quantity)
          : updateBOQCalculationQuantity(
              rowData.boq_calculation_id,
              changes.quantity
            );
      }
    }

    if (!action) {
      action = updateSpecificationSetFixedColumn(
        _.last(formData.id.split('-')),
        formData
      );
    }

    return validateForm(
      action.then(() => {
        refreshData();
      })
    );
  };

  const _handleDynamicDataSubmit = (
    id,
    type,
    specification_product_attribute_group_id,
    value,
    rowData,
    column_id
  ) => {
    let formData = {};

    const fieldName = `attr-${id}`;
    if (type == 'string') {
      formData[fieldName] = _.size(value) > 0 ? value : {};
    } else {
      formData[fieldName] = value;
    }

    let action;

    if (specification_product_attribute_group_id) {
      action = updateSpecificationProductAttributesGroupFields(
        specification_product_attribute_group_id,
        formData
      );
    } else {
      action = updateGenericSpecificationProductAttributesGroupFields(
        _.last(rowData.id.split('-')),
        column_id,
        formData
      );
    }

    return validateForm(
      action.then(() => {
        refreshData();
      })
    );
  };

  // const _handleUnitChange = useCallback((formData, unit_id) => {
  //   const action =
  //     formData.type === 'generic_specification'
  //       ? _handleFixedDataSubmit({ ...formData, price })
  //       : updateBOQCalculationUnit(formData.boq_calculation_id, unit_id);

  //   action.then(() => {
  //     refreshData();
  //   });
  // }, []);

  // const _handlePriceChange = useCallback((formData, price) => {
  //   const action =
  //     formData.type === 'generic_specification'
  //       ? _handleFixedDataSubmit({ ...formData, price })
  //       : updateSpecificationPrice(_.last(_.split(formData.id, '-')), {
  //           price,
  //         });

  //   action.then(() => {
  //     refreshData();
  //   });
  // }, []);

  // const _handleQuantityChange = useCallback((rowData, quantity) => {
  //   if (rowData.type == 'generic_specification') {
  //     _handleFixedDataSubmit({ ...rowData, quantity });
  //   } else {
  //     const [specification_subgroup_id, specification_id, position_id] =
  //       _.split(rowData.id, '-', 3);

  //     let action = position_id
  //       ? updateBOQPositionQuantity(position_id, quantity)
  //       : updateBOQCalculationQuantity(rowData.boq_calculation_id, quantity);

  //     return action.then(() => refreshData());
  //   }
  // }, []);

  const columns = [
    {
      key: 'name',
      required: true,
      title: 'Title',
      width: 300,
      minWidth: 200,
      resizable: true,
      sortable: false,
      frozen: 'left',
      cellRenderer: ({ rowData }) =>
        rowData.id === 'UN' ? (
          <span>{__('specification-set.table.row.unallocated')}</span>
        ) : (
          <>
            {rowData.checked === true && (
              <CheckedIcon
                className={styles.checked}
                onClick={() => _handleCheckbox(rowData.id)}
              />
            )}
            {rowData.checked === false && (
              <UncheckedIcon
                className={classNames(
                  styles.unchecked,
                  checked.length > 0 && styles.visible
                )}
                onClick={() => _handleCheckbox(rowData.id)}
              />
            )}
            {rowData.checked === 'neutral' && (
              <NeutralIcon
                className={styles.neutral}
                onClick={() => _handleCheckbox(rowData.id)}
              />
            )}

            {/* {!_.includes(rowData.id, 'UN') &&
            (rowData.level < 3 ? (
              <span className={styles.code}>{rowData.code}</span>
            ) : (
              <span className={styles.codePlaceholder} />
            ))} */}

            {/* {!_.includes(rowData.id, 'UN') && (
              <span className={styles.codePlaceholder} />
            )} */}
            {/* <span className={styles.codePlaceholder} /> */}

            {rowData.level == 0 && (
              <div
                className={classNames(
                  styles.addRowActions,
                  dropdown && rowData.id == focusedId && styles.fixedOpacity
                )}
              >
                <Dropdown
                  top={5}
                  left={5}
                  name={'specification-set-' + rowData.id + '-add-row-actions'}
                  wrapperClassName={styles.dropdownWrapper}
                  content={
                    <div className={styles.actionsContent}>
                      {_.map(specificationActionRow, (button, i) => {
                        return (
                          !button.disabled && (
                            <Tooltip text={button.label} key={i}>
                              <div
                                onClick={() => button.onClick(rowData.id)}
                                className={styles.action}
                              >
                                <span>{button.label}</span>
                              </div>
                            </Tooltip>
                          )
                        );
                      })}
                    </div>
                  }
                >
                  <span onClick={() => setFocusedId(rowData.id)}>
                    <Tooltip
                      text={__('specification_set_item.table.row.add-boq-item')}
                    >
                      +
                    </Tooltip>
                  </span>
                </Dropdown>
              </div>
            )}

            <div className={classNames(styles.nameContainer)}>
              {rowData.specification_id ? (
                <Tooltip text='Go to specification'>
                  <div
                    className={classNames(
                      styles.input,
                      styles.bold,
                      styles.inputSpecification
                    )}
                    onClick={() => {
                      readWorkingSetSpecification(
                        rowData.specification_id
                      ).then(({ response }) => {
                        setTableParams('working_set_specifications', {
                          flyout: response.data,
                          clicked_row: response.data.id,
                        });
                      });
                    }}
                  >
                    {rowData.name}
                    <ArrowDoubleRightSmallIcon />
                  </div>
                </Tooltip>
              ) : (
                <div className={classNames(styles.input, styles.bold)}>
                  {rowData.level == 2 && _.get(rowData.name, languageId)}

                  {_.includes([0, 1, 3], rowData.level) && rowData.name}
                </div>
              )}

              {rowData.level == 1 &&
                rowData.type == 'generic_specification' && (
                  <div
                    className={
                      styles.createSpecificationFromGenericSpecification
                    }
                    onClick={() => {
                      setTableParams('specification_sets', {
                        wizard_convert_generic_specification_to_specification:
                          rowData,
                      });
                    }}
                  >
                    <Tooltip
                      text={__(
                        'specification_set_item.table.row.convert-to-specification'
                      )}
                    >
                      <ConvertSpecificationIcon />
                    </Tooltip>
                  </div>
                )}

              {rowData.specification_id && (
                <div
                  className={styles.editSpecificationCode}
                  onClick={() => {
                    if (rowData.level == 1 && rowData.specification_id) {
                      readWorkingSetSpecification(
                        rowData.specification_id
                      ).then(({ response }) => {
                        setTableParams('working_set_specifications', {
                          flyout: response.data,
                          edit_specification_code_wizard: true,
                        });
                      });
                    }
                  }}
                >
                  <EditIcon />
                </div>
              )}
            </div>
          </>
        ),
    },

    {
      key: 'thumb',
      required: false,
      title: 'Thumbnail',
      width: 80,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        _.includes([1, 3], rowData.level) &&
        (rowData.thumbnail_url ? (
          <Tooltip
            placement='left'
            text={
              <Image
                className={styles.thumbPreview}
                src={rowData.thumbnail_url}
              />
            }
            html={true}
            dark={true}
          >
            <Image className={styles.thumb} src={rowData.thumbnail_url} />
          </Tooltip>
        ) : (
          <TableNA />
        )),
    },

    {
      key: 'description',
      required: false,
      title: 'Description',
      width: 250,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        _.includes([1, 3], rowData.level) && (
          <input
            className={classNames(styles.input)}
            type='text'
            ref={(ref) => setNameRef(rowData.id, ref)}
            onBlur={({ currentTarget }) =>
              _handleFixedDataSubmit({
                ...rowData,
                description: { [languageId]: currentTarget.value },
              })
            }
            key={rowData.id + _.get(rowData.description, languageId)}
            defaultValue={_.get(rowData.description, languageId)}
            maxLength={70}
          />
        ),
    },

    {
      key: 'unit',
      title: 'Unit',
      required: false,
      align: 'right',
      width: 70,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        // Specification groups
        const unit_options = _.sortBy(
          _.map(units, (unit, i) => {
            const unit_group = _.get(unit_groups, unit.unit_group_id, {});

            return {
              value: unit.id,
              label: unit.symbol,
              group: getLocalized(unit_group.name, languageId),
              priority: unit_group.priority,
            };
          }),
          'priority'
        );

        return _.includes([1, 3], rowData.level) ? (
          <SearchableDropdown
            top={25}
            right={-15}
            multiselect={false}
            dropdownWrapperClassName={styles.dropdownWrapper}
            name={'unit.' + rowData.id}
            header={<div className={styles.dropdownHeader}>Unit</div>}
            groupReadOnly
            options={unit_options}
            onSelect={({ value }) => {
              _handleFixedDataSubmit(rowData, { unit_id: value });
            }}
            buttonLabel={__('button.cancel')}
          >
            <div className={styles.dropdown}>
              {rowData.unit_id && (
                <span>{_.get(units, [rowData.unit_id, 'symbol'])}</span>
              )}
              {!rowData.unit_id && <span className={styles.none}>--</span>}
              <ArrowDownSmallIcon />
            </div>
          </SearchableDropdown>
        ) : (
          ''
        );
      },
    },

    {
      key: 'price',
      required: false,
      title: `Price`,
      width: 100,
      align: 'right',
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (_.includes([1, 2, 3], rowData.level)) {
          return (
            <NumericInput
              key={rowData.id + rowData.price}
              defaultValue={rowData.price}
              onBlur={(value) =>
                _handleFixedDataSubmit(rowData, { price: value })
              }
              readOnly={rowData.level == 2}
            />
          );
        }
      },
    },

    {
      key: 'quantity',
      required: false,
      title: `Quantity`,
      width: 100,
      align: 'right',
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (_.includes([1, 2, 3], rowData.level)) {
          return (
            <NumericInput
              key={rowData.id + rowData.quantity}
              defaultValue={rowData.quantity}
              onBlur={(value) =>
                _handleFixedDataSubmit(rowData, {
                  quantity: unformatMoney(value),
                })
              }
              readOnly={!_.isEmpty(rowData.children) && rowData.level == 1}
            />
          );
        }
      },
    },

    {
      key: 'manufacturer_company_id',
      required: false,
      title: 'Manufacturer company',
      width: 150,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        _.includes([1, 3], rowData.level) && (
          <SearchableDropdown
            top={25}
            right={-15}
            multiselect={false}
            dropdownWrapperClassName={styles.dropdownWrapper}
            name={'manufacturer_company_id.' + rowData.id}
            header={
              <div className={styles.dropdownHeader}>Manufacturer company</div>
            }
            options={possible_address_book_manufacturers}
            onSelect={({ value }) => {
              _handleFixedDataSubmit({
                ...rowData,
                manufacturer_company_id: value,
              });
            }}
            buttonLabel={__('button.cancel')}
            noneValue
          >
            <div className={styles.dropdown}>
              {rowData.manufacturer_company_id && (
                <span>
                  {
                    _.find(possible_address_book_manufacturers, {
                      value: rowData.manufacturer_company_id,
                    })?.label
                  }
                </span>
              )}
              {!rowData.manufacturer_company_id && (
                <span className={styles.none}>--</span>
              )}
              <ArrowDownSmallIcon />
            </div>
          </SearchableDropdown>
        ),
    },

    {
      key: 'supplier_company_id',
      required: false,
      title: 'Supplier company',
      width: 150,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        _.includes([1, 3], rowData.level) && (
          <SearchableDropdown
            top={25}
            right={-15}
            multiselect={false}
            dropdownWrapperClassName={styles.dropdownWrapper}
            name={'supplier_company_id.' + rowData.id}
            header={
              <div className={styles.dropdownHeader}>Supplier company</div>
            }
            options={possible_address_book_suppliers}
            onSelect={({ value }) => {
              _handleFixedDataSubmit({
                ...rowData,
                supplier_company_id: value,
              });
            }}
            buttonLabel={__('button.cancel')}
            noneValue
          >
            <div className={styles.dropdown}>
              {rowData.supplier_company_id && (
                <span>
                  {
                    _.find(possible_address_book_suppliers, {
                      value: rowData.supplier_company_id,
                    })?.label
                  }
                </span>
              )}
              {!rowData.supplier_company_id && (
                <span className={styles.none}>--</span>
              )}
              <ArrowDownSmallIcon />
            </div>
          </SearchableDropdown>
        ),
    },

    {
      key: 'responsible_user_id',
      required: false,
      title: 'Responsible user',
      width: 150,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        return (
          _.includes([1, 3], rowData.level) && (
            <SearchableDropdown
              top={25}
              right={-15}
              multiselect={false}
              dropdownWrapperClassName={styles.dropdownWrapper}
              name={'responsible_user_id.' + rowData.id}
              header={
                <div className={styles.dropdownHeader}>Responsible user</div>
              }
              options={_.map(possible_specification_responsible_users, (user) =>
                user.value === rowData.responsible_user_id
                  ? _.merge({}, user, {
                      label: rowData.responsible_user_fullname,
                    })
                  : user
              )}
              onSelect={({ value }) => {
                _handleFixedDataSubmit({
                  ...rowData,
                  responsible_user_id: value,
                });
              }}
              buttonLabel={__('button.cancel')}
            >
              <div className={styles.dropdown}>
                {rowData.responsible_user_id && (
                  <span>
                    {
                      _.find(possible_specification_responsible_users, {
                        value: rowData.responsible_user_id,
                      })?.label
                    }
                  </span>
                )}
                {!rowData.responsible_user_id && (
                  <span className={styles.none}>--</span>
                )}
                <ArrowDownSmallIcon />
              </div>
            </SearchableDropdown>
          )
        );
      },
    },

    ..._.map(dynamicColumns, (column, key) => {
      return {
        key: column.name,
        title: column.name,
        width: 130,
        resizable: true,
        sortable: false,
        cellRenderer: ({ rowData, rowIndex }) => {
          if (
            (rowData.level == 1 || _.includes(rowData.id, 'UN')) &&
            _.get(rowData.dynamic_columns, column.id)
          ) {
            const boolean_options = [
              {
                label: __('working-set-specifications.attributes-form.yes'),
                value: '1',
              },
              {
                label: __('working-set-specifications.attributes-form.no'),
                value: '0',
              },
            ];

            const attribute = _.get(rowData.dynamic_columns, column.id);

            const currentAttribute = attribute?.template_field_id
              ? _.get(product_attribute_fields, attribute.template_field_id, {})
              : attribute;
            const type = currentAttribute.type;
            let label = getLocalized(currentAttribute.label, languageId);
            label +=
              (attribute.unit_id || currentAttribute.unit_id) &&
              _.includes(['numeric', 'range', 'range_selectable'], type)
                ? ' (' + _.get(units, [attribute.unit_id, 'symbol']) + ')'
                : '';

            if (
              type == 'range_selectable' &&
              attribute.range_from &&
              attribute.range_to
            ) {
              label += ` [${_.toFinite(attribute.range_from)} ~ ${_.toFinite(
                attribute.range_to
              )}]`;
            }

            label += !attribute?.template_field_id ? '*' : '';

            const fieldName = `attr-${attribute.id}-${rowData.id}`;
            const value = attribute.value;

            let unit = _.get(units, [attribute.unit_id, 'symbol']);

            return (
              <>
                {type === 'boolean' && (
                  <SearchableDropdown
                    top={25}
                    right={-15}
                    multiselect={false}
                    dropdownWrapperClassName={styles.dropdownWrapper}
                    name={fieldName}
                    header={
                      <div className={styles.dropdownHeader}>{label}</div>
                    }
                    options={boolean_options}
                    onSelect={({ value }) => {
                      _handleDynamicDataSubmit(
                        attribute.id,
                        type,
                        attribute?.group_id,
                        value,
                        rowData,
                        column.id
                      );
                    }}
                    buttonLabel={__('button.cancel')}
                    noneValue
                  >
                    <div className={styles.dropdown}>
                      <span>
                        {_.get(
                          _.find(boolean_options, { value: attribute.value }),
                          'label'
                        )}
                      </span>

                      <ArrowDownSmallIcon />
                    </div>
                  </SearchableDropdown>
                )}

                {type == 'dropdown_string' && (
                  <SearchableDropdown
                    top={25}
                    right={-15}
                    multiselect={false}
                    dropdownWrapperClassName={styles.dropdownWrapper}
                    name={fieldName}
                    header={
                      <div className={styles.dropdownHeader}>{label}</div>
                    }
                    options={_.map(
                      _.find(product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ])?.options,
                      (option) => ({
                        value: option.id,
                        label: getLocalized(option.value, languageId),
                        disabled: toBoolean(option.discontinued),
                      })
                    )}
                    onSelect={({ value }) => {
                      _handleDynamicDataSubmit(
                        attribute.id,
                        type,
                        attribute?.group_id,
                        value,
                        rowData,
                        column.id
                      );
                    }}
                    buttonLabel={__('button.cancel')}
                    noneValue
                  >
                    <div className={styles.dropdown}>
                      <span>
                        {getLocalized(
                          _.get(
                            _.find(
                              _.find(product_attribute_fields, [
                                'id',
                                attribute.template_field_id,
                              ])?.options,
                              ['id', _.toNumber(value)]
                            ),
                            'value'
                          ),
                          languageId
                        )}
                      </span>

                      <ArrowDownSmallIcon />
                    </div>
                  </SearchableDropdown>
                )}

                {type == 'dropdown_string_multiple' && (
                  <MultiSelect
                    input={{
                      value: value,
                      onChange: (value) => {
                        _handleDynamicDataSubmit(
                          attribute.id,
                          type,
                          attribute?.group_id,
                          value,
                          rowData,
                          column.id
                        );
                      },
                      onBlur: (value) => {
                        setActiveComponent('');
                      },
                      onFocus: () => {
                        setActiveComponent(fieldName);
                      },
                    }}
                    meta={{
                      active: activeComponent === fieldName, // Use state to determine if it's active
                    }}
                    options={_.map(
                      _.find(product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ])?.options,
                      (option) => ({
                        value: option.id,
                        label: getLocalized(option.value, languageId),
                        disabled: toBoolean(option.discontinued),
                      })
                    )}
                    hideBarAndAssist
                  />
                )}

                {type == 'dropdown_numeric' && (
                  <SearchableDropdown
                    top={25}
                    right={-15}
                    multiselect={false}
                    dropdownWrapperClassName={styles.dropdownWrapper}
                    name={fieldName}
                    header={
                      <div className={styles.dropdownHeader}>{label}</div>
                    }
                    options={_.map(
                      _.find(product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ]).options,
                      (option) => {
                        let unit = _.get(units, [attribute.unit_id, 'symbol']);

                        return {
                          value: option.id,
                          label:
                            _.toString(getLocalized(option.value, languageId)) +
                            (unit ? ' (' + unit + ')' : ''),
                          disabled: toBoolean(option.discontinued),
                        };
                      }
                    )}
                    onSelect={({ value }) => {
                      _handleDynamicDataSubmit(
                        attribute.id,
                        type,
                        attribute?.group_id,
                        value,
                        rowData,
                        column.id
                      );
                    }}
                    buttonLabel={__('button.cancel')}
                    noneValue
                  >
                    <div className={styles.dropdown}>
                      <span>
                        {getLocalized(
                          _.get(
                            _.find(
                              _.find(product_attribute_fields, [
                                'id',
                                attribute.template_field_id,
                              ])?.options,
                              ['id', _.toNumber(value)]
                            ),
                            'value',
                            ''
                          ),
                          languageId
                        ) + (unit ? ' (' + unit + ')' : '')}
                      </span>

                      <ArrowDownSmallIcon />
                    </div>
                  </SearchableDropdown>
                )}

                {type == 'dropdown_numeric_multiple' && (
                  <MultiSelect
                    input={{
                      value: value,
                      onChange: (value) => {
                        _handleDynamicDataSubmit(
                          attribute.id,
                          type,
                          attribute?.group_id,
                          value,
                          rowData,
                          column.id
                        );
                      },
                      onBlur: (value) => {
                        setActiveComponent('');
                      },
                      onFocus: () => {
                        setActiveComponent(fieldName);
                      },
                    }}
                    meta={{
                      active: activeComponent === fieldName, // Use state to determine if it's active
                    }}
                    options={_.map(
                      _.find(product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ])?.options,
                      (option) => {
                        let unit = _.get(units, [attribute.unit_id, 'symbol']);

                        return {
                          value: option.id,
                          label:
                            _.toString(getLocalized(option.value, languageId)) +
                            (unit ? ' (' + unit + ')' : ''),
                          disabled: toBoolean(option.discontinued),
                        };
                      }
                    )}
                    hideBarAndAssist
                  />
                )}

                {type == 'string' && (
                  <input
                    className={styles.input}
                    defaultValue={getLocalized(attribute.value, languageId)}
                    type='text'
                    key={rowData.id}
                    onBlur={({ currentTarget }) => {
                      _handleDynamicDataSubmit(
                        attribute.id,
                        type,
                        attribute?.group_id,
                        { languageId: currentTarget.value },
                        rowData,
                        column.id
                      );
                    }}
                  />
                )}

                {_.includes(['numeric', 'range_selectable'], type) && (
                  <NumericInput
                    key={rowData.id}
                    defaultValue={attribute.value}
                    onBlur={(value) => {
                      _handleDynamicDataSubmit(
                        attribute.id,
                        type,
                        attribute?.group_id,
                        unformatMoney(value),
                        rowData,
                        column.id
                      );
                    }}
                  />
                )}

                {type == 'color' && (
                  <ColorPicker
                    topPosition={5}
                    input={{
                      value: value,
                      onChange: (value) => {
                        _handleDynamicDataSubmit(
                          attribute.id,
                          type,
                          attribute?.group_id,
                          value,
                          rowData,
                          column.id
                        );
                      },
                      onBlur: (value) => {
                        setActiveComponent('');
                      },
                      onFocus: () => {
                        setActiveComponent(fieldName);
                      },
                    }}
                    meta={{
                      active: activeComponent === fieldName, // Use state to determine if it's active
                    }}
                    noSort
                    hideBarAndAssist
                  />
                )}

                {type == 'material' && (
                  <MaterialPicker
                    topPosition={5}
                    input={{
                      value: value,
                      onChange: (value) => {
                        _handleDynamicDataSubmit(
                          attribute.id,
                          type,
                          attribute?.group_id,
                          value,
                          rowData,
                          column.id
                        );
                      },
                      onBlur: (value) => {
                        setActiveComponent('');
                      },
                      onFocus: () => {
                        setActiveComponent(fieldName);
                      },
                    }}
                    meta={{
                      active: activeComponent === fieldName, // Use state to determine if it's active
                    }}
                    hideBarAndAssist
                  />
                )}

                {/* {type == 'range' && (
                  <div className={styles.range}>
                    <Tooltip text={labelTooltip}>
                      <div className={styles.label}>{label}</div>
                    </Tooltip>
                    <div className={styles.flex}>
                      <div className={styles.f1}>
                        <NumericInput
                          key={rowData.id + '.0'}
                          defaultValue={attribute.value}
                          onBlur={(value) => {
                            _handleDynamicDataSubmit(
                              attribute.id,
                              type,
                              attribute?.group_id,
                              value
                            );
                          }}
                        />
                      </div>
                      <span className={styles.minus}>-</span>
                      <div className={styles.f1}>
                        <NumericInput
                          key={rowData.id + '.1'}
                          defaultValue={attribute.value}
                          onBlur={(value) => {
                            _handleDynamicDataSubmit(
                              attribute.id,
                              type,
                              attribute?.group_id,
                              value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )} */}
              </>
            );
          }
        },
      };
    }),

    {
      key: 'actions',
      title: '',
      width: 35,
      resizable: false,
      sortable: false,
      frozen: 'right',
      required: true,
      cellRenderer: ({ rowData }) => {
        if (!_.includes(rowData.id, 'UN-')) return;

        return (
          <div
            className={styles.actions}
            onClick={(e) => {
              const x = e.clientX;
              const y = e.clientY;

              const { name, id } = rowData;

              setSelectedItemContext({
                name,
                id,
              });

              showMenu({
                position: { x, y },
                id: 'specification_set_context_menu',
              });
            }}
          >
            <MenuVIcon
              className={classNames(
                rowData.id === selectedItemContext.id && styles.active
              )}
            />
          </div>
        );
      },
    },

    {
      key: 'placeholder',
      title: '',
      width: 0,
      resizable: false,
      sortable: false,
      frozen: 'right',
      required: true,
    },
  ];

  const headerRenderer = ({ headerIndex, cells, columns }) => {
    if (headerIndex === 0) {
      return _.map(columns, (column, i) => {
        if (column.key === 'name') {
          const levels = {
            2: [],
            3: [],
          };

          _.each(data, (group) => {
            !_.isEmpty(group.children) && levels[2].push(group.id);

            _.each(group.children, (subgroup) => {
              !_.isEmpty(subgroup.children) && levels[3].push(subgroup.id);
            });
          });

          let active = {};

          for (let i = 2; i <= 3; i++) {
            active[i] = _.every(levels[i], (id) =>
              _.includes(expandedRows, id)
            );
          }

          return React.cloneElement(
            cells[i],
            null,
            <>
              <div className={styles.placeholder} />
              <div className={styles.masterActions}>
                <Tooltip text='Show group-level'>
                  <span
                    className={classNames(styles.active)}
                    onClick={() => _handleMasterExpand([], true)}
                  >
                    1
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[2]) ? '' : 'Show type-level'}>
                  <span
                    className={classNames(
                      active[2] && styles.active,
                      _.isEmpty(levels[2]) && styles.disabled
                    )}
                    onClick={() => _handleMasterExpand(levels[2], true)}
                  >
                    2
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[3]) ? '' : 'Show item-level'}>
                  <span
                    className={classNames(
                      active[2] && active[3] && styles.active,
                      _.isEmpty(levels[3]) && styles.disabled
                    )}
                    onClick={() =>
                      _handleMasterExpand([...levels[2], ...levels[3]], true)
                    }
                  >
                    3
                  </span>
                </Tooltip>
              </div>
              <div className='BaseTable__header-cell-text'>Title</div>
            </>
          );
        } else {
          return React.cloneElement(cells[i], null);
        }
      });
    } else {
      return _.map(columns, (column, i) => {
        let children = _.filter(cells[i].props.children, _.identity);

        let value = '';

        if (column.key === 'name') {
          value = (
            <div className='BaseTable__header-cell-text'>
              {checked.length === data_ids.length && checked.length !== 0 && (
                <CheckedIcon
                  className={classNames(styles.checked, styles.master)}
                  onClick={_handleMasterCheckbox}
                />
              )}
              {checked.length === 0 && (
                <UncheckedIcon
                  className={classNames(
                    styles.unchecked,
                    styles.master,
                    checked.length > 0 && styles.visible
                  )}
                  onClick={_handleMasterCheckbox}
                />
              )}
              {checked.length !== data_ids.length && checked.length !== 0 && (
                <NeutralIcon
                  className={classNames(styles.neutral, styles.master)}
                  onClick={_handleMasterCheckbox}
                />
              )}
            </div>
          );
        }

        children[column.frozen ? 1 : 0] = <span>{value}</span>;

        return React.cloneElement(cells[i], null, children);
      });
    }
  };

  return (
    <>
      <AutoSizer>
        {({ height, width }) => (
          <BaseTable
            ref={(ref) => {
              setRef(ref);

              tableRef = ref;
            }}
            rowClassName={({ rowData }) =>
              classNames(
                rowData.level === 0 && styles.darkGray,
                rowData.level === 1 && styles.lightGray,
                styles.row,
                rowData.id === focusedId && styles.focused,
                rowData.id === focusedId && styles.leftFloatingColumn,
                rowData.id === 'UN' && styles.unallocatedGroup
              )
            }
            fixed
            rowProps={({ rowData, rowIndex }) => ({
              // tagName: Row,
              index: rowIndex,
              id: rowData.id,
              unallocatedGroupCollapsed: isUnallocatedCollapsed,
              onClick: () => {
                if (!_.includes(rowData.id, 'UN')) {
                  setFocusedId(rowData.id);
                  setTableParams('specification_sets', {
                    rowIndex,
                    rowData: rowData,
                  });
                }
              },
              level: rowData.level,
              parentId: rowData.parentId,
            })}
            components={{
              ExpandIcon,
            }}
            expandIconProps={({ rowData }) => ({
              depth: rowData.level,
              unallocated_group_collapsed: isUnallocatedCollapsed,
              id: rowData.id,
              unallocated_group_click_handler: () =>
                setUnallocatedCollapsed(!isUnallocatedCollapsed),
            })}
            expandColumnKey='name'
            columns={_.filter(
              columns,
              ({ key, required }) =>
                required || !_.includes(disabledColumns, key)
            )}
            data={final_data}
            width={width}
            height={height}
            onExpandedRowsChange={setExpandedRows}
            ignoreFunctionInColumnCompare={false}
            headerHeight={[32, 32]}
            headerRenderer={headerRenderer}
            headerClassName={({ headerIndex }) =>
              classNames(
                headerIndex === 0 && styles.firstHeaderRow,
                headerIndex === 1 && styles.secondHeaderRow
              )
            }
            //estimatedRowHeight={32}
            rowHeight={32}
          />
        )}
      </AutoSizer>

      <ContextMenu
        id='specification_set_context_menu'
        className={styles.contextMenu}
        onHide={() => {
          setSelectedItemContext({});
        }}
      >
        {/* --- MOVE GENERIC SPECIFICATION --- */}
        <MenuItem
          onClick={() =>
            setTableParams('specification_sets', {
              move_generic_specification_wizard: {
                ..._.pick(selectedItemContext, ['id', 'name']),
              },
            })
          }
        >
          <Tooltip
            text={__(
              'specification_set_item.table.row.action.tooltip.move_generic_specification_wizard'
            )}
          >
            <div className={styles.item}>
              <span className={styles.icon}>
                <MoveIcon />
              </span>
              <span>
                {__(
                  'specification_set_item.table.row.action.label.move_generic_specification_wizard'
                )}
              </span>
            </div>
          </Tooltip>
        </MenuItem>
      </ContextMenu>
    </>
  );
};

export default withRouter(TableSpecificationSetItems);
